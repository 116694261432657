import React from 'react'; 
import Footer from '../Components/Footer';
import Header from '../Components/Header';
import { Grid, Card, CardContent, CardMedia, Typography } from '@mui/material';
import pic1 from '../assets/images/pic1.jpg';
import pic2 from '../assets/images/pic2.jpg';
import pic3 from '../assets/images/pic3.avif';
import pic4 from '../assets/images/pic4.jpg';
import pic5 from '../assets/images/pic5.PNG';

const AboutPage = () => {
  return (
    <>
      <Header />
      <div className="about-container">
        {/* Hero Section */}
        <section className="hero-section">
          <div className="hero-content">
            <Typography variant="h2" align="center">About Us</Typography>
            <Typography variant="body1" align="center">
              Discover who we are and what we stand for.
            </Typography>
          </div>
        </section>

        {/* Mission Section */}
        <section className="mission-section">
          <div className="mission-content" style={{display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}}>
            <Typography variant="h4">Our Mission</Typography>
            <Typography variant="body1" sx={{textAlign:'justify',width:'80%',justifyContent:'center',display:'flex'}}>
            At Earthlink Business, we strive to be the catalyst for global business success. Our mission is to deliver innovative solutions that empower businesses and individuals to thrive in diverse international markets. We are dedicated to creating sustainable value, fostering growth, and building strong partnerships that enable our clients to navigate the complexities of starting and expanding their businesses across borders. Together, we aim to unlock opportunities, overcome challenges, and foster vibrant communities worldwide.
                 </Typography>
          </div>
        </section>

        {/* Values Section */}
        <section className="values-section">
          <div className="values-content">
            <Typography variant="h4">Our Values</Typography>
            <ul>
              <li><strong>Integrity:</strong> We act with honesty and transparency in everything we do.</li>
              <li><strong>Innovation:</strong> We constantly seek new and creative ways to improve.</li>
              <li><strong>Customer Success:</strong> Our customers’ growth is our top priority.</li>
              <li><strong>Excellence:</strong> We strive for perfection in every aspect of our work.</li>
            </ul>
          </div>
        </section>

        {/* Team Section */}
        <section className="team-section">
          <Typography variant="h4" align="center">Meet Our Team</Typography>
          <Grid container spacing={2} justifyContent="center">
            {[
              { name: "KABANDA Vianny", title: "CEO & Founder", img: pic1 },
              { name: "KAKWEZI Annet", title: "HR", img: pic4 },
              { name: "MURENGEZI MUSHYO Elia", title: "Visa Consaltant Manager", img: pic2 },
              { name: "NYAWERA Elsa", title: "Students Consaltant Manager", img: pic3 },              
              { name: "TUYISABE Pacifique", title: "IT Manager", img: pic5 },
            ].map((member, index) => (
              <Grid item xs={12} sm={6} md={4} lg={2.4} key={index}>
                <Card sx={{height:'100%',width:'100%',display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}}>
                  <CardMedia
                    component="img"
                    image={member.img}
                    alt={member.name}
                    sx={{
                      width: '80%',  // Responsive width
                      height: '60%', // Maintain aspect ratio
                      borderRadius: '50%', // Circular image styling
                      objectFit: 'revert', // Cover the card space
                    }}
                  />
                  <CardContent>
                    <Typography variant="h6" align="center">{member.name}</Typography>
                    <Typography variant="body2" align="center">{member.title}</Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </section>

        {/* Call to Action Section */}
        <section className="cta-section">
          <div className="cta-content">
            <Typography variant="h4" align="center">Join Us on Our Journey</Typography>
            <Typography variant="body1" align="center">
              Become part of our mission to create a better future. Get in touch with us today!
            </Typography>
            <a href="/business-contact" className="cta-button">Contact Us</a>
          </div>
        </section>
      </div>
      <Footer />
    </>
  );
};

export default AboutPage;
