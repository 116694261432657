import React from 'react';
import { Container, Grid, Box, Typography, Button, Card, CardContent } from '@mui/material';
import Header from '../Components/Header';
import Footer from '../Components/Footer';

import PrivateOfficeIcon from '../assets/images/private-office.png';
import CoworkingSpaceIcon from '../assets/images/coworking-space.png';
import VirtualOfficeIcon from '../assets/images/virtual-office.png'; 
import BusinessSetupIcon from '../assets/images/business-setup.png';

const services = [
  {
    title: "Private Office",
    description: "Secure, fully-furnished office spaces for businesses needing dedicated work environments.",
    icon: PrivateOfficeIcon,
  },
  {
    title: "Co-working Space",
    description: "Flexible, shared workspaces designed for collaboration and networking.",
    icon: CoworkingSpaceIcon,
  },
  {
    title: "Virtual Office",
    description: "Professional business address, mail handling, and meeting room access without a physical office.",
    icon: VirtualOfficeIcon,
  },
  {
    title: "Business Set Up Services",
    description: "Comprehensive support for establishing your business internationally, from registration to compliance.",
    icon: BusinessSetupIcon,
  },
];

const ServicesPage = () => {
  return (
    <>
      <Header />
      <Container maxWidth="lg" sx={{ mt: 20 }}>
        {/* Intro Section */}
        <Box sx={{ textAlign: 'center', mb: 8 }}>
          <Typography variant="h2" color="primary" gutterBottom>
            Launch Your International Presence
          </Typography>
          <Typography variant="body1" color="textSecondary" sx={{ maxWidth: 700, mx: 'auto' }}>
            Navigate the complexities of setting up and expanding your business across borders with our specialized support.
          </Typography>
        </Box>

        {/* Services Section */}
        <Box sx={{ textAlign: 'center', mb: 8 }}>
          <Typography variant="h3" gutterBottom>
            Our International Business Setup Services
          </Typography>
          <Typography variant="body1" color="textSecondary" sx={{ maxWidth: 700, mx: 'auto' }}>
            We offer a range of services to help businesses establish a presence internationally, from private office space to comprehensive business setup support.
          </Typography>
        </Box>

        <Grid container spacing={4}>
          {services.map((service, index) => (
            <Grid item xs={12} md={6} key={index}>
              <Card sx={{ height: '100%', boxShadow: 3, ':hover': { boxShadow: 6 } }}>
                <CardContent>
                  <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
                    <img src={service.icon} alt={service.title} style={{ width: 80, height: 80 }} />
                  </Box>
                  <Typography variant="h5" gutterBottom textAlign="center">
                    {service.title}
                  </Typography>
                  <Typography variant="body2" color="textSecondary" textAlign="center">
                    {service.description}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>

        {/* Benefits Section */}
        <Box sx={{ textAlign: 'center', mt: 8, backgroundColor: '#f9f9f9', py: 6, px: 4 }}>
          <Typography variant="h4" gutterBottom color="primary">
            Why Choose Us?
          </Typography>
          <Grid container spacing={4}>
            <Grid item xs={12} md={4}>
              <Typography variant="h5" color="textPrimary" gutterBottom>
                Expert Guidance
              </Typography>
              <Typography variant="body2" color="textSecondary">
                Our team brings years of experience to support your expansion and help you navigate regulatory environments.
              </Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography variant="h5" color="textPrimary" gutterBottom>
                Seamless Setup
              </Typography>
              <Typography variant="body2" color="textSecondary">
                From company registration to office setup, we handle every detail to ensure a smooth launch.
              </Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography variant="h5" color="textPrimary" gutterBottom>
                Comprehensive Support
              </Typography>
              <Typography variant="body2" color="textSecondary">
                Whether it's legal compliance or workspace solutions, we provide all the resources you need to succeed.
              </Typography>
            </Grid>
          </Grid>
        </Box>

        {/* Call to Action */}
        <Box sx={{ textAlign: 'center', mt: 8 }}>
          <Typography variant="h4" gutterBottom>
            Ready to Start Your Business Internationally?
          </Typography>
          <Typography variant="body1" color="textSecondary" sx={{ maxWidth: 600, mx: 'auto', mb: 4 }}>
            Contact us today to learn more about how we can help set up your business in any country with ease and expertise.
          </Typography>
          <Button variant="contained" color="primary" size="large">
            Request a Quote
          </Button>
        </Box>

        {/* Contact Information Section */}
        <Box sx={{ mt: 10, textAlign: 'center', py: 6, px: 4, backgroundColor: '#1976d2', color: 'white' }}>
          <Typography variant="h4" gutterBottom>
            Have Questions?
          </Typography>
          <Typography variant="body1" sx={{ maxWidth: 700, mx: 'auto', mb: 4 }}>
            Reach out to our team for more details on setting up your business or any other inquiries. We're here to help!
          </Typography>
          <Button variant="outlined" color="inherit" size="large" href="/contact">
            Contact Us
          </Button>
        </Box>
      </Container>
      <Footer />
    </>
  );
};

export default ServicesPage;
