import React, { useState, useEffect } from 'react';
import { Grid, Typography, Box, IconButton } from '@mui/material';
import CircleIcon from '@mui/icons-material/Circle';
import BackgroundImage1 from '../assets/images/slide1.jpg';  // Replace with your image paths
import BackgroundImage2 from '../assets/images/slide2.jpg';
import BackgroundImage3 from '../assets/images/slide3.jpg';
import BackgroundImage4 from '../assets/images/slide4.jpg';

const backgrounds = [BackgroundImage1, BackgroundImage2, BackgroundImage3, BackgroundImage4];

const HeroSection = () => {
  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveIndex((prevIndex) => (prevIndex + 1) % backgrounds.length);
    }, 5000); // Change slide every 5 seconds
    return () => clearInterval(interval);
  }, []);

  const handleSlideChange = (index) => {
    setActiveIndex(index);
  };

  return (
    <Box
      sx={{
        height: '90vh',
        backgroundImage: `url(${backgrounds[activeIndex]})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: 'white',
        position: 'relative',
        transition: 'background-image 1s ease-in-out', // Smooth transition for background image
      }}
    >
      <Grid container spacing={2} justifyContent="center" alignItems="center">
        <Grid item xs={12} md={6}>
          <Typography variant="h3" align="center" sx={{ fontWeight: 'bold' }}>
            Welcome to Our Service
          </Typography>
          <Typography variant="h6" align="center" sx={{ mt: 2 }}>
            We provide top-notch services for all your needs.
          </Typography>
        </Grid>
      </Grid>

      {/* Circle Navigation Buttons */}
      <Box
        sx={{
          position: 'absolute',
          bottom: '20px',
          left: '50%',
          transform: 'translateX(-50%)',
          display: 'flex',
          gap: '10px',
        }}
      >
        {backgrounds.map((_, index) => (
          <IconButton
            key={index}
            onClick={() => handleSlideChange(index)}
            sx={{
              color: activeIndex === index ? 'white' : 'rgba(255, 255, 255, 0.5)',
              fontSize: activeIndex === index ? '15px' : '12px',
            }}
          >
            <CircleIcon />
          </IconButton>
        ))}
      </Box>
    </Box>
  );
};

export default HeroSection;
