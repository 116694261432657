import React from 'react';
import { Box, Typography, Grid, Paper } from '@mui/material';
import WorkOutlineIcon from '@mui/icons-material/WorkOutline'; // for Private Office
import AppsIcon from '@mui/icons-material/Apps'; // for Co-Working Space
import CloudQueueIcon from '@mui/icons-material/CloudQueue'; // for Virtual Office
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter'; // for Business Setup Services
import { styled } from '@mui/material/styles';

// Define a styled component for the icon animation
const AnimatedIcon = styled(Box)(({ theme }) => ({
  fontSize: '50px',
  color: '#28a745',
  transition: 'transform 0.3s ease-in-out',
  '&:hover': {
    transform: 'scale(1.2)',
  }
}));

// Define the data for the features
const features = [
  {
    icon: <WorkOutlineIcon sx={{ fontSize: '50px',color:'#1976d2' }} />,
    title: 'PRIVATE OFFICE',
    description: 'Fully furnished, secure offices tailored for businesses seeking a dedicated workspace. Ideal for companies needing privacy, it provides all the amenities required to run your operations smoothly.'
  },
  {
    icon: <AppsIcon sx={{ fontSize: '50px',color:'#1976d2' }} />,
    title: 'CO-WORKING SPACE',
    description: 'A flexible, shared workspace designed for freelancers, startups, and businesses of all sizes. It fosters collaboration and networking, offering a cost-effective solution with access to professional resources and facilities.'
  },
  {
    icon: <CloudQueueIcon sx={{ fontSize: '50px',color:'#1976d2' }} />,
    title: 'VIRTUAL OFFICE',
    description: ' A remote business address service that allows companies to maintain a professional image without needing a physical office. It includes mail handling, call answering, and access to meeting rooms when needed.'
  },
  {
    icon: <BusinessCenterIcon sx={{ fontSize: '50px',color:'#1976d2' }} />,
    title: 'BUSINESS SET UP SERVICES',
    description: 'Comprehensive support to establish your business in international markets. We assist with legal registration, compliance, tax, and administrative requirements, ensuring a smooth and efficient setup process.'
  }
];

const FeatureCard = ({ icon, title, description }) => {
  return (
    <Grid item xs={12} sm={6} md={3}>
      <Paper elevation={3} sx={{ padding: 4, textAlign: 'center', height: '80%' }}>
        <AnimatedIcon>{icon}</AnimatedIcon>
        <Typography variant="h6" sx={{ fontWeight: 'bold', marginTop: 2 }}>
          {title}
        </Typography>
        <Typography variant="body2" sx={{ marginTop: 2,fontSize:17,textAlign:'initial' }}>
          {description}
        </Typography>
      </Paper>
    </Grid>
  );
};

const FeaturesSection = () => {
  return (
    <Box sx={{ padding: 4 ,marginBottom:20}}>
      <Typography variant="h4" align="center" gutterBottom>
        FEATURES
      </Typography>
      <Typography variant="subtitle1" align="center" sx={{ marginBottom: 4 }}>
        Exclusive offices...
      </Typography>
      <Grid container spacing={10}>
        {features.map((feature, index) => (
          <FeatureCard
            key={index}
            icon={feature.icon}
            title={feature.title}
            description={feature.description}
          />
        ))}
      </Grid>
    </Box>
  );
};

export default FeaturesSection;
