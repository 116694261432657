import React, { useState } from 'react';
import { Box, Typography, IconButton, Collapse } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

// Data for the accordion
const accordionData = [
  {
    title: "Why Getting a Brand Identity in different countries Should be Your Priority?",
    content: "Getting a brand identity in different countries should be a priority because it helps build a strong and recognizable presence in global markets. A localized brand identity:Increases Trust: Tailoring your brand to fit cultural preferences builds trust with local customers.Improves Competitiveness: A distinctive identity sets you apart from competitors and attracts more customers.Boosts Recognition: Consistent branding across regions makes your business memorable and easy to identify.Drives Expansion: A well-defined brand identity eases entry into new markets and accelerates growth."
  },
  {
    title: "What is Corporate Identity?",
    content: "Corporate identity is the overall image and personality of a business that distinguishes it in the marketplace. It encompasses visual elements like logos, colors, typography, and design, as well as communication style and company values. A strong corporate identity helps create a consistent brand experience across all platforms, building trust and recognition with customers, employees, and stakeholders. It reflects the core values and mission of a company, shaping how it is perceived in the market. By establishing a clear and cohesive corporate identity, businesses can enhance their reputation, attract loyal customers, and position themselves for long-term success."
  },
  {
    title: "Why Starting International business?",
    content: "Starting a business internationally opens up new opportunities for growth, access to larger markets, and increased revenue potential. Expanding beyond local borders allows businesses to tap into diverse customer bases, reduce dependency on a single market, and take advantage of different economic conditions. It also helps enhance brand recognition and competitiveness on a global scale. By going international, businesses can diversify their operations, minimize risks, and unlock greater innovation and collaboration opportunities."
  },
  {
    title: "Brand Identity for your company international is something you cannot overlook?",
    content: "Brand identity for your company internationally is something you cannot overlook because it is the key to establishing a strong, consistent presence across global markets. A clear and tailored brand identity helps build trust with diverse audiences, ensuring your company is easily recognized and respected in each region. It also enhances competitiveness, making your products or services stand out while reflecting the values and mission of your business. A strong international brand identity allows you to connect with local cultures, foster customer loyalty, and drive successful business growth worldwide."
  }
];

const AboutUsSection = () => {
  const [expanded, setExpanded] = useState(null); // State to track the currently expanded section

  const handleToggle = (index) => {
    setExpanded(expanded === index ? null : index); // Toggle the clicked section
  };

  return (
    <Box sx={{ width: '100%', marginTop: 4,justifyContent:'center',alignItems:'center',display:'flex',flexDirection:'column' }}>
      {accordionData.map((item, index) => (
        <Box
          key={index}
          sx={{
            border: '2px solid #1976d2', // Green border
            borderRadius: '5px',
            marginBottom: 2,
            padding: 2,
            backgroundColor: '#fff',
            width:'80%',
            height:'100%'
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              cursor: 'pointer',
              transition: 'all 0.3s ease-in-out'
            }}
            onClick={() => handleToggle(index)}
          >
            <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
              {item.title}
            </Typography>
            <IconButton sx={{color:'#1976d2'}}>
              {expanded === index ? <RemoveIcon /> : <AddIcon />}
            </IconButton>
          </Box>

          <Collapse in={expanded === index} timeout="auto" unmountOnExit>
            <Typography variant="body1" sx={{ marginTop: 2, transition: 'ease-in-out 1s',fontSize:17,cursor:'pointer' }}>
              {item.content}
            </Typography>
          </Collapse>
        </Box>
      ))}
    </Box>
  );
};

export default AboutUsSection;
