import React from 'react';
import { Box, Grid, Typography, IconButton } from '@mui/material';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';

const Footer = () => {
  return (
    <Box sx={{ backgroundColor: '#1976d2', color: 'white', padding: '30px 20px' }}>
      <Grid container spacing={3}>
        {/* Column 1: About Us */}
        <Grid item xs={12} sm={4}>
          <Typography variant="h6" sx={{ marginBottom: '15px' }}>
            About Us
          </Typography>
          <Typography variant="body2">
            Earthlink Business Center offers premium office spaces and comprehensive services to help businesses establish themselves international.
          </Typography>
        </Grid>

        {/* Column 2: Contact Info */}
        <Grid item xs={12} sm={4}>
          <Typography variant="h6" sx={{ marginBottom: '15px' }}>
            Contact Us
          </Typography>
          <Typography variant="body2">+250 788 447 599</Typography>
          <Typography variant="body2">+250 788 447 599</Typography>
          <Typography variant="body2">info@earthlinkgroup.com</Typography>
        </Grid>

        {/* Column 3: Follow Us */}
        <Grid item xs={12} sm={4}>
          <Typography variant="h6" sx={{ marginBottom: '15px' }}>
            Follow Us
          </Typography>
          <IconButton color="inherit" href="https://www.linkedin.com">
            <LinkedInIcon />
          </IconButton>
          <IconButton color="inherit" href="https://www.facebook.com">
            <FacebookIcon />
          </IconButton>
          <IconButton color="inherit" href="https://www.twitter.com">
            <TwitterIcon />
          </IconButton>
        </Grid>
      </Grid>
      
      {/* Copyright Section */}
      <Box textAlign="center" sx={{ marginTop: '20px' }}>
        <Typography variant="body2">
          &copy; {new Date().getFullYear()} Earthlink Business Center. All rights reserved.
        </Typography>
      </Box>
    </Box>
  );
};

export default Footer;
