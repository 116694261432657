import React, { useState, useEffect } from 'react';
import { AppBar, Toolbar, Typography, Box, IconButton, Drawer, List, ListItem, ListItemText } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import YouTubeIcon from '@mui/icons-material/YouTube';
import { Link } from 'react-router-dom';  // Import Link from react-router-dom
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import logo from '../assets/images/logo.png';

const navItems = [
  { "name": "Home", "link": "/business-home" },
  { "name": "About Us", "link": "/business-aboutus" },
  { "name": "Business Services", "link": "/business-service" },
  { "name": "Contact", "link": "/business-contact" }
];

const Header = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md')); // Adjust for mobile responsiveness

  const handleScroll = () => {
    setIsScrolled(window.scrollY > 50);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  return (
    <>
      {/* Contact Header */}
      <AppBar
        position="fixed"
        elevation={0}
        sx={{
          backgroundColor: '#1976d2',
          color: 'white',
          display: isScrolled ? 'none' : 'block',
          padding: '5px 20px',
          borderBottomWidth: '1px',
        }}
      >
        <Toolbar sx={{ justifyContent: 'space-between' }}>
          <Box display="flex" alignItems="center">
            <PhoneIcon sx={{ mr: 1 }} />
            <Typography variant="body2">+250 788 447 599</Typography>
            <EmailIcon sx={{ ml: 2, mr: 1 }} />
            <Typography variant="body2">info@earthlinkgroup.com</Typography>
          </Box>
          <Box>
            <IconButton sx={{ color: 'white' }} size="small">
              <FacebookIcon />
            </IconButton>
            <IconButton sx={{ color: 'white' }} size="small">
              <TwitterIcon />
            </IconButton>
            <IconButton sx={{ color: 'white' }} size="small">
              <LinkedInIcon />
            </IconButton>
            <IconButton sx={{ color: 'white' }} size="small">
              <YouTubeIcon />
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>

      {/* Main Menu Header */}
      <AppBar
        position="fixed"
        elevation={0}
        sx={{
          backgroundColor: isScrolled ? 'white' : 'transparent',
          color: isScrolled ? 'black' : 'white',
          transition: 'background-color 0.5s ease, color 0.5s ease',
          padding: isScrolled ? '10px 20px' : '20px 20px',
          boxShadow: isScrolled ? '0px 2px 10px rgba(0, 0, 0, 0.1)' : 'none',
          top: isScrolled ? '0px' : '50px',
        }}
      >
        <Toolbar sx={{ justifyContent: 'space-between' }}>
          {/* Logo Section */}
          <Box display="flex" alignItems="center">
            <img
              src={logo}
              alt="Logo"
              style={{
                height: isMobile ? '40px' : '100px', // Smaller logo on mobile
                marginRight: '20px',
                cursor: 'pointer'
              }}
            />
            <Typography variant="h6" sx={{ cursor: 'pointer', fontWeight: '700', color: '#1976d2', fontSize: 24 }}>
              Earthlink Business
            </Typography>
          </Box>

          {/* Navigation Menu or Hamburger Menu */}
          {isMobile ? (
            <>
              <IconButton onClick={toggleDrawer} sx={{ color: isScrolled ? 'black' : 'white' }}>
                <MenuIcon />
              </IconButton>
              <Drawer anchor="right" open={drawerOpen} onClose={toggleDrawer}>
                <Box
                  sx={{ width: 250 }}
                  role="presentation"
                  onClick={toggleDrawer}
                  onKeyDown={toggleDrawer}
                >
                  <List>
                    {navItems.map((item) => (
                      <ListItem button key={item.name}>
                        <Link
                          to={item.link}  // Use 'to' attribute for routing
                          style={{
                            color: 'inherit',
                            textDecoration: 'none',
                            width: '100%', // Make the link fill the ListItem
                          }}
                        >
                          <ListItemText primary={item.name} />
                        </Link>
                      </ListItem>
                    ))}
                  </List>
                </Box>
              </Drawer>
            </>
          ) : (
            <Box>
              {navItems.map((item, index) => (
                <Link
                  key={index}
                  to={item.link}  // Use 'to' attribute for routing
                  style={{
                    color: isScrolled ? 'black' : '#1976d2',
                    marginRight: '20px',
                    textDecoration: 'none',
                    fontWeight: '600',
                    transition: 'color 0.3s ease',
                    fontSize: '20px',
                  }}
                >
                  {item.name}
                </Link>
              ))}
            </Box>
          )}
        </Toolbar>
      </AppBar>
    </>
  );
};

export default Header;
