import React from 'react';
import { FaPhone, FaEnvelope, FaMapMarkerAlt, FaFacebook, FaTwitter, FaInstagram } from 'react-icons/fa';
import Header from '../Components/Header';
import Footer from '../Components/Footer';
import image from '../assets/images/International.avif'

const ContactPage = () => {
  return (
    <>
      <Header />
      
      {/* Top Image */}
      <div style={styles.topImageContainer}>
        <img src={image} alt="Contact Background" style={styles.image} />
      </div>

      <div style={styles.container}>
        <h1 style={styles.header}>Contact Us</h1>
        
        <div style={styles.content}>
          {/* Contact Information */}
          <div style={styles.contactInfo}>
            <h2>Get in Touch</h2>
            <p>If you have any questions or inquiries, feel free to contact us. We are here to help you.</p>
            
            <div style={styles.infoRow}>
              <FaMapMarkerAlt style={styles.icon} />
              <p>123 Main Street, Kigali, Rwanda</p>
            </div>
            
            <div style={styles.infoRow}>
              <FaPhone style={styles.icon} />
              <p>+250 788 447 599</p>
            </div>
            
            <div style={styles.infoRow}>
              <FaEnvelope style={styles.icon} />
              <p>info@earthlinkgroup.com</p>
            </div>

            <h3>Follow Us</h3>
            <div style={styles.socialIcons}>
              <FaFacebook style={styles.socialIcon} />
              <FaTwitter style={styles.socialIcon} />
              <FaInstagram style={styles.socialIcon} />
            </div>
          </div>

          {/* Contact Form */}
          <div style={styles.formContainer}>
            <h2>Send Us a Message</h2>
            <form style={styles.form}>
              <input style={styles.input} type="text" placeholder="Your Name" required />
              <input style={styles.input} type="email" placeholder="Your Email" required />
              <input style={styles.input} type="text" placeholder="Subject" required />
              <textarea style={styles.textarea} placeholder="Your Message" rows="5" required></textarea>
              <button type="submit" style={styles.button}>Submit</button>
            </form>
          </div>
        </div>
      </div>

      {/* Bottom Image */}
      <div style={styles.bottomImageContainer}>
        <img src={image} alt="Contact Background" style={styles.image} />
      </div>
      
      <Footer />
    </>
  );
};

const styles = {
  container: {
    padding: '20px',
    backgroundColor: '#f8f9fa',
    fontFamily: 'Arial, sans-serif',
    textAlign: 'center',
  },
  header: {
    fontSize: '2.5rem',
    color: '#333',
    marginBottom: '20px',
  },
  content: {
    display: 'flex',
    justifyContent: 'space-around',
    flexWrap: 'wrap',
    marginTop: '20px',
  },
  contactInfo: {
    maxWidth: '400px',
    textAlign: 'left',
  },
  infoRow: {
    display: 'flex',
    alignItems: 'center',
    margin: '10px 0',
  },
  icon: {
    fontSize: '1.5rem',
    marginRight: '10px',
    color: '#555',
  },
  socialIcons: {
    display: 'flex',
    justifyContent: 'start',
    marginTop: '15px',
  },
  socialIcon: {
    fontSize: '1.8rem',
    color: '#007bff',
    marginRight: '10px',
    cursor: 'pointer',
  },
  formContainer: {
    maxWidth: '400px',
    textAlign: 'left',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
  },
  input: {
    padding: '10px',
    margin: '10px 0',
    borderRadius: '5px',
    border: '1px solid #ccc',
    fontSize: '1rem',
  },
  textarea: {
    padding: '10px',
    margin: '10px 0',
    borderRadius: '5px',
    border: '1px solid #ccc',
    fontSize: '1rem',
  },
  button: {
    padding: '10px',
    backgroundColor: '#007bff',
    color: '#fff',
    border: 'none',
    borderRadius: '5px',
    fontSize: '1rem',
    cursor: 'pointer',
    marginTop: '10px',
  },
  topImageContainer: {
    width: '100%',
    height: '300px',
    overflow: 'hidden',
  },
  bottomImageContainer: {
    width: '100%',
    height: '200px',
    overflow: 'hidden',
    marginTop: '20px',
  },
  image: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
};

export default ContactPage;
