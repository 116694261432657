import React from 'react';
import Footer from '../Components/Footer';
import Header from '../Components/Header';
import HeroSection from './HeroSection';
import ServicesSection from './ServicesSection';
import AboutUsSection from './AboutUsSection';
import FeaturesSection from './FeaturesSection';

const LandingPage = () => {
  return (
    <>
      <Header/>
      <HeroSection/>
      <ServicesSection/>
      <AboutUsSection/>
      <FeaturesSection/>
      <Footer />
    </>
  );
};

export default LandingPage;
