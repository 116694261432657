import React from 'react';
import { Grid, Box, Typography, Paper } from '@mui/material';
import WorkspacesIcon from '@mui/icons-material/Workspaces'; // Example icon, replace with actual icons if needed
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import MeetingRoomIcon from '@mui/icons-material/MeetingRoom';
import StorefrontIcon from '@mui/icons-material/Storefront';
import '../styles/styles.css'; // Assuming you'll use this CSS file for animations

const services = [

  {
    title: 'Business Set Up Services',
    description:
      'As a consultant company, we guide clients through every step of the process, from legal registration, tax compliance, and obtaining necessary permits to navigating local regulations.Our services simplify international expansion, ensuring a smooth and efficient setup in new markets, allowing businesses to focus on growth.',
    icon: <StorefrontIcon fontSize="large" sx={{fontSize:'70px',color:'#1976d2'}} />,
  },
  {
    title: 'Global company registration',
    description:
      'Global Company Registration services help businesses establish a presence in multiple countries. We assist with legal requirements, from company name registration and documentation to acquiring licenses and setting up banking. Our expertise ensures compliance with local laws, making global expansion smooth and efficient.',
    icon: <WorkspacesIcon fontSize="large" sx={{fontSize:'60px',color:'#1976d2'}}/>,
  },
  {
    title: 'accounting and tax',
    description:
      'Our Accounting and Tax services help businesses manage their finances and comply with tax regulations. As a consulting firm, we provide bookkeeping, financial reporting, tax filing, and advisory services, ensuring accurate records and compliance with local tax laws, so businesses can focus on growth',
    icon: <BusinessCenterIcon fontSize="large" sx={{fontSize:'70px',color:'#1976d2'}} />,
  },
  {
    title: 'Secretary and legal registered office',
    description:
      'To comply with local company law, Healy Consultants provides to our Clients  global company secretary services and  legal registered office service.Companies are bodies with a legal personality separate from that of their owners.',
    icon: <MeetingRoomIcon fontSize="large" sx={{fontSize:'70px',color:'#1976d2'}} />,
  },
  
];

const ServicesSection = () => {
  return (
    <Box sx={{ padding: '50px 20px', textAlign: 'center' }}>
      <Typography variant="h4" gutterBottom sx={{ fontWeight: 'bold' }}>
        WE PRIDE OURSELVES
      </Typography>
      <Typography variant="subtitle1" sx={{ mb: 5 }}>
        Serviced Offices, Business Packages, Conference rooms, and meeting facilities in the United Arab Emirates.
      </Typography>

      <Grid container spacing={4} justifyContent="center">
        {services.map((service, index) => (
          <Grid item xs={12} md={3} key={index}>
            <Paper elevation={3} sx={{ padding: '30px 20px', borderRadius: '10px', textAlign: 'center',height:'80%' }}>
              <Box className="icon-wrapper animated-icon" sx={{ marginBottom: '20px', color: 'green' }}>
                {service.icon}
              </Box>
              <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                {service.title}
              </Typography>
              <Typography variant="body2" sx={{ mt: 2,textAlign:'initial',fontSize:17 }}>
                {service.description}
              </Typography>
            </Paper>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default ServicesSection;
